.textarea {
    width: 90vw;
    height: 30vh;
    padding: 16px;
    font-size: 1em;
    font-family: "futura", monospace;
    border: 1px solid #E1E2E3;
    outline: 0;
    border-radius: 4px;
    background: white;
    opacity: 0.9;
  }

  .img{
    width: 72px;
  }

  .column {
    float: left;
    width: 33.3%;
    padding: 5px;
  }
  
  /* Clear floats after image containers */
  .row::after {
    content: "";
    clear: both;
    display: table;
  }
.vas-text{
  font-size: 16px;
  font-weight: 600;
  color: #21B572;
}

.futura-16-300 {
  font-family : futura;
}

.bold {
  font-weight: 600;
}

.font-size {
  font-size: 24px;
}