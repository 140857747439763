@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima\ Nova\ Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima\ Nova\ Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima\ Nova\ Bold.otf") format("opentype");
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Proxima Nova";
  color: #2A2E35;
  min-height: 100%;
}

header {
  background: #ff5a66;
  color: #fff;
  text-align: center;
  flex-grow: 0;
}

h1 {
  font-weight: 300;
  padding: 0.4em 0;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  height: 100%;
}

form {
  max-width: 300px;
  margin: 0 auto;
}

h2 {
  font-weight: 300;
  margin-bottom: 1em;
  text-align: center;
}

form>div {
  width: 100%;
  margin-bottom: 1em;
}

form>div>label {
  display: block;
  margin-bottom: 0.3em;
}

form>div>input.sign-in {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid #2A2E35;
}

.control {
  display: flex;
  background-color: #2A2E35;
  z-index: 2;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 6px;
  align-items: center;
  justify-content: center;
  transition: 1000ms;
  white-space: nowrap;
}

footer {
  background: #2A2E35;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  padding: 1em 0;
}

footer a {
  color: #fff;
}

.center {
  text-align: center;
}

.green-button {
  height: 56px;
  font-family: "futura";
  letter-spacing: 0.5px;
  font-size: 18px;
  font-weight: 900;
  width: 300px;
  border-radius: 28px;
  background-color: #74D173;
  color: white;
}

.green-button:hover {
  cursor: pointer;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-top-64 {
  margin-top: 64px;
}

.margin-top-80 {
  margin-top: 80px;
}

.futura-20-900 {
  font-family: "futura";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 24px;
}

.futura-20-300 {
  font-family: "futura";
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 24px;
}

.oops {
  font-family: "futura";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 24px;
}

.margin-sides {
  margin-left: 16px;
  margin-right: 16px;
}

.futura-14-300 {
  color: #363B44;
  font-family: "futura";
  font-size: 14px;
  letter-spacing: 0;
}

.onboarding-bottom-nav-bar {
  background-color: white;
  text-align: center;
  padding-bottom: 8px;
  padding-top: 8px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

input.sign-in {
  display: block;
  margin: 10px 0 20px 0;
  width: 30vw;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "futura";
  height: 56px;
  border: 1px solid #E1E2E3;
  outline: 0;
  border-radius: 4px;
  background: white;
  opacity: 0.9;
}

.home-nav-bar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 2;
  height: 30px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
}

/* .local-participant .screenShare { */

/*   transform: scaleX(-1); */

/* } */

/* .remote-participant .webcam { */

/*   object-fit: contain; */

/*   width: 100%; */

/*   height: 100%; */

/* } */

/* .local-participant .webcam { */

/*   object-fit: cover; */

/*   width: auto; */

/*   max-width: 50vw; */

/*   height: auto; */

/* } */

/* .local-participant .webcam { */

/*   transform: scaleX(-1); */

/* } */

/* .local-participant .webcam--sharingScreen { */

/*   display: none; */

/* } */

/* .video-container { */

/*   position: relative; */

/*   display: block; */

/*   width: 80vw; /\* set your width here in vw *\/ */

/*   padding: 0; */

/*   overflow: hidden; */

/* } */

/* .video-container::before { */

/*   display: block; */

/*   content: ""; */

/* } */

/* .video-container::before { */

/*   padding-top: 56.25%; /\* video W:H 16:9 *\/ */

/* } */

/* .video-container video, */

/* .video-container iframe { */

/*   position: absolute; */

/*   top: 0; bottom: 0; left: 0; */

/*   width: 100%; */

/*   height: 100%; */

/*   border: 0; */

/* } */

/* .room { */

/*   position: fixed; */

/*   background: #2A2E35; */

/*   height: 100%; */

/*   width: 100%; */

/* } */

/* .room button { */

/*   position: absolute; */

/*   top: 0; */

/*   right: 20px; */

/* } */

/* .room > h3 { */

/*   text-align: center; */

/*   font-weight: 300; */

/* } */

/* .local-participant { */

/*   position: fixed; */

/*   right: 2vw; */

/*   min-width: 25vw; */

/*   bottom: 68px; */

/*   text-align: center; */

/* } */

/* .remote-participants { */

/*   width: 40%; */

/*   height: 100vh; */

/* } */

/* .participant { */

/*   background: #2A2E35; */

/*   padding: 10px; */

/*   border-radius: 6px; */

/*   display: inline-block; */

/*   margin-right: 10px; */

/* } */

/* .participant:last-child { */

/*   margin-right: 0; */

/* } */

/* .participant h3 { */

/*   text-align: center; */

/*   padding-bottom: 0.5em; */

/*   color: #fff; */

/* } */

/* video { */

/*   border-radius: 15px; */

/*   width: 100%; */

/*   height: 100%; */

/*   margin: 0 auto; */

/* } */

.room {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  height: calc(100% - 60px - 3rem);
  padding-bottom: 3rem;
  align-items: center;
}

.room__name {
 position: relative;
}

.room__name-text {
  width: 150px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.room__name:hover .room__full-name, .room__name:active .room__full-name, .room__name:focus-within .room__full-name {
  opacity: 1;
}

.room__full-name {
  position: absolute;
  top: 2.0rem;
  background: rgba(0,0,0,0.5);
  border-radius: 6px;
  padding: .5rem;
  opacity: 0;
  transition: opacity .3s;
}
.room__full-name--visible {
  opacity: 1;
}

.room.maximized {
  flex-flow: column nowrap;
}

.local-participant, .remote-participant {
  width: 100%;
  height: 50%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  min-height: 100px;
}


.local-participant.maximize, .remote-participant.maximize {
  flex: 10;
  width: 100%;
  height: 100%;
}


.local-participant.minimize, .remote-participant.minimize {
  flex: 1;
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: calc(3rem + 10px);
  right: 10px;
  z-index: 9999;
  opacity: .5;
}

.local-participant.minimize .video-container, .remote-participant.minimize .video-container {
  background: transparent;
}

.remote-participant {
  margin-bottom: 1rem;
}

.video-container__text {
  font-family: "Proxima Nova";
  position: absolute;
  z-index: 999;
  bottom: .5rem;
  left: .5rem;
  padding: .3rem;
  background: rgba(0,0,0,.5);
  color: white;
  font-size: .8rem;
  border-radius: .25rem;
}

.video-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  transform: translateZ(0);
  background: rgb(42, 46, 53);
  max-width: 100%;
  height: 100%;
  position: relative;
}

.video-container:hover .video__options-toggler, .video-container:focus .video__options-toggler, .video-container:active .video__options-toggler {
  display: flex;
}

.video__options-toggler {
  display: none;
  color: #FFF;
  position: absolute;
  justify-content: center;
  padding: .3rem;
  border-radius: .25rem;
  right: .5rem;
  top: .5rem;
  z-index: 9999;
  background: rgba(0,0,0,.5);
}

.video__options {
  display: flex;
  flex-flow: column;
  padding: .5rem;
  font-size: .8rem;
  color: hsl(0, 0%, 95%);
  position: absolute;
  top: calc(.5rem + 1.5rem);
  right: .5rem;
  background: rgba(0,0,0,.5);
  border-radius: .25rem;
  z-index: 9999;
}

.video__options--hidden {
  display: none;
}

.video__options-item {
  cursor: pointer;
  padding: .3rem;
}

.video__options-item:hover {
  background: rgba(0,0,0,.1);
  border-radius: .25rem;
  color: hsl(0, 0%, 100%);
}

video {
  /* transform: scaleX(-1); */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.local-participant .webcam {
  transform: scaleX(-1);
}

.canvas { 
  position: absolute;
  z-index: 500; 
  width: 100% !important; 
  height: 100% !important;
}

.remote-participant .screen {
  transform: scaleX(1);
  object-fit: contain;
}

.local-participant .screen {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.25) translateY(0);
  transform-origin: bottom right;
  transition: transform 0.5s ease, opacity 0.5s 0.25s ease;
  object-fit: contain;
  z-index: 999;
  bottom: 8px;
  right: 16px;
  opacity: .5;
}

.local-participant .screen:hover {
  opacity: 1;
  transform: scale(0.75) translateY(8px);
}

.remote-participant::before {
  position: absolute;
  bottom: .5rem;
  left: .5rem;
  padding: .3rem;
  content: "Waiting for participant...";
  font-family: "Proxima Nova";
  background: rgba(0,0,0,.5);
  color: white;
  font-size: .8rem;
  border-radius: .25rem;
  display: block;
}

.remote-participant::after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  min-height: 65px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.remote-participant.minimize::after {
  background: rgba(0, 0, 0, 0.1);
}

.remote-participant.joined:before, .remote-participant.joined:after {
  display: none;
}

.remote-participant.joined {}

.navbar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 60px;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.navbar__status {
  margin-left: 1rem;
  height: 75%;
  border-radius: 12px;
  background: #1C1E21;
  color: #FFF;
  justify-content: space-between;
  align-items: center;
  font-family: "Proxima Nova";
  font-weight: 700;
  padding: 1rem;
  display: flex;
}

.navbar__logo-mobile {
  height: 2.5rem;
}

.navbar__logo {
  display: none;
}



@media (min-width: 960px) {
  .room.maximized {
    flex-flow: row nowrap;
  }

  .local-participant.minimize, .remote-participant.minimize {
    width: 250px;
    height: auto;
    margin: 1rem;
  }

  .local-participant.maximize .video-container, .remote-participant.maximize .video-container {
    background: #000;
  }
  
  .local-participant.maximize video, .remote-participant.maximize video {
    width: 50vw;
    border-radius: 0;
  }

}

@media (min-width: 960px) {
  .navbar {
    margin-bottom: 18px;
  }

  .navbar__logo-mobile {
    display: none;
  }

  .navbar__logo {
    display: block;
    margin-right: 1rem;
  }

  .local-participant, .remote-participant {
    height: 100%;
    width: 49%;
    border-radius: 12px;
  }
  .remote-participant {
    margin-bottom: 0;
  }
  .video-container, .video-container video {
    border-radius: inherit;
  }

  .remote-participant:before, .video-container__text {
    padding: 0;
    background: none;
    border-radius: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 14px;
  }

  .video-container__text {
    position: static;
  }

  .remote-participant:before {
    position: static;
    padding: 0;
  }

  .remote-participant:after {
    border-radius: 12px;
  }
  .room {
    margin-top: 0;
    height: calc(100% - 120px - 1rem);
  }

  .custom-cursor {
    cursor: url('./assets/icons/edit_color_2.svg'), auto !important;
    transform: rotate();
  }
  /* .room { */
  /*     display: flex; */
  /*     width: 100%; */
  /*     height: calc(100% - 60px); */
  /*     justify-content: center; */
  /*     align-items: center; */
  /* } */
  /* .local-participant { */
  /*   position: static; */
  /*   width: 45%; */
  /*   height:60vh; */
  /*   margin: 0 2.5%; */
  /* }  */
  /* .remote-participant .webcam, */
  /* .local-participant .webcam { */
  /*   height: auto; */
  /*   width: auto; */
  /* } */
  /* .remote-participant { */
  /*   width: 45%; */
  /*   height: 60vh; */
  /* } */
  /* .remote-participant:before { */
  /*   content: "sample-name"; */
  /*   font-size: 18px; */
  /*   font-family: "futura"; */
  /*   font-size: 18px; */
  /*   font-weight: 900; */
  /*   letter-spacing: 0.25px; */
  /*   line-height: 24px; */
  /*   display: block; */
  /*   margin-top: 8px; */
  /*   margin-left: 8px; */
  /* } */
  /* .remote-participant:after {   */
  /*   content: " "; */
  /*   display: block; */
  /*   width: 100%; */
  /*   height: 100%; */
  /*   background: #AAA; */
  /*   border-radius: 10px; */
  /*   overflow:hidden; */
  /* } */
  /* .remote-participant.joined:before, .remote-participant.joined:after { */
  /*   display: none; */
  /* } */
  /* .remote-participant.joined { */
  /* } */
}


@media (min-width: 400px) and (orientation: landscape) {
  .local-participant.maximize .video-container, .remote-participant.maximize .video-container {
    background: #000;
  }
  
  .local-participant.maximize video, .remote-participant.maximize video {
    width: 50vw;
    border-radius: 0;
  }

  .remote-participant, .local-participant {
    width: 49%;
    height: 100%;
  }
  
  .remote-participant {
    margin-bottom: 0;
  }

  .remote-participant::before {
    position: absolute;
    bottom: .5rem;
    left: .5rem;
    padding: .3rem;
    content: "Waiting for participant...";
    font-family: "Proxima Nova";
    background: rgba(0,0,0,.5);
    color: white;
    font-size: .8rem;
    border-radius: .25rem;
    display: block;
  }
  
  .remote-participant::after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    min-height: 65px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
  }

  .video-container__text {
    font-family: "Proxima Nova";
    position: absolute;
    z-index: 999;
    bottom: .5rem;
    left: .5rem;
    padding: .3rem;
    background: rgba(0,0,0,.5);
    color: white;
    font-size: .8rem;
    border-radius: .25rem;
  }
}

@media (min-width: 1000px) and (orientation: landscape) {
  .remote-participant, .local-participant {
    height: 50vh;
  }
}

@media (min-width: 1260px) and (orientation: landscape) {
  .remote-participant, .local-participant {
    height: 100%;
  }
}